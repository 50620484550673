<template>
  <div class="content-footer">
    <p class="footer-login">
      Powered by
      <span @click="openSofex()" class="text-sofex text-decoration-underline"
        >Sofex Technologies</span
      >
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    openSofex(){
      window.open('https://www.sofex.com.mx')
    }
  }
};
</script>
<style scoped>
.content-footer{
    width: 100%;
    text-align: center;
    margin-top: 5px;
}
.footer-login {
  color: var(--primary-color-text);
  font-size: 14px;
  font-family: "pop-Regular";
}
.text-sofex {
  color: var(--primary-color-footer-link);
  cursor: pointer;
  font-size: 14px;
  font-family: "pop-Regular";
}
</style>